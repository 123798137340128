import React from 'react';
import { NavLink } from 'react-router-dom';
import Tick from "../Assets/Images/CodesBunny/tick.png";
import Bunny from "../Assets/Images/CodesBunny/Bunnie.png";
import Servicerow from "./Servicerow";
import web from '../Assets/Images/CodesBunny/web.png';
import App from '../Assets/Images/CodesBunny/AppDev.png';


const Mobapplication = () => {
  return (
    <>
      <section className="aboutMainHero">
        <div className="aboutHeroContainer">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="aboutHeroContent">
                <h2>Crafting Digital Solutions at CodesBunny</h2>
                <p>
                  We specialize in creating exceptional online experiences through our comprehensive range of services.
                </p>
                <ul>
                  <li>
                    <img src={Tick} decoding="async" alt="Tick icon" /> Wide range of digital services
                  </li>
                  <li>
                    <img src={Tick} decoding="async" alt="Tick icon" /> Exceptional online experiences
                  </li>
                  <li>
                    <img src={Tick} alt="Tick icon" decoding="async" /> Expertise with user understanding
                  </li>
                </ul>
                <NavLink to="/about" className="aboutHeroBtn">
                  Learn More
                </NavLink>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <img src={Bunny} alt="Animated bunny" style={{ height: '450px' }} decoding="async" className="aboutAnimatedImage" />
            </div>
          </div>
        </div>
      </section>

      <Servicerow />

      <section className="news-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6">
              <div className="news-details__left">
                <div className="news-details__content">
                  <h3 className="serWebcontenttitle">App Development</h3>
                  <p className="serWebtext1">
                    Our app development services focus on creating feature-rich, high-performance mobile applications for Android and iOS platforms. Utilizing technologies such as Java, Kotlin, Swift, and Flutter, we deliver apps that are tailored to meet the unique needs of our clients. Our app development process includes:
                    <br /><br />
                    <strong style={{ color: '#00C9FF' }}>Requirements Gathering:</strong> Understanding the client's objectives and defining the project scope.
                    <br />
                    <strong style={{ color: '#00C9FF' }}>Design & Architecture:</strong> Designing a user-friendly interface and scalable architecture.
                    <br />
                    <strong style={{ color: '#00C9FF' }}>Development:</strong> Bringing the design to life with robust coding practices.
                    <br />
                    <strong style={{ color: '#00C9FF' }}>Testing & Quality Assurance:</strong> Ensuring the app functions flawlessly across different devices and operating systems.
                    <br />
                    <strong style={{ color: '#00C9FF' }}>Deployment & Support:</strong> Launching the app and providing ongoing support for maintenance and updates.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="news-details__img" data-aos="zoom-in">
                <img src={App} alt="Web Development" decoding="async" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Mobapplication;

