import React from "react";

const Servicerow = () => {
  return (
    <div>
      <section class="sectionserviceRow">
        <div class="serviceRow">

          <div class="serviceItem"><span class="star">⭐</span> Web Development</div>
          <div class="serviceItem"> <span class="star">⭐</span> App Development</div>
          <div class="serviceItem"> <span class="star">⭐</span> Digital Marketing</div>
          <div class="serviceItem"><span class="star">⭐</span> Content Writing</div>
          <div class="serviceItem"> <span class="star">⭐</span> Search Engine Optimization </div>
          <div class="serviceItem"> <span class="star">⭐</span> App Store Optimization </div>
          <div class="serviceItem"> <span class="star">⭐</span> Hospital Management System</div>
          <div class="serviceItem "> <span class="star">⭐</span> Learning Management System</div>
          <div class="serviceItem "> <span class="star">⭐</span> Enterprise Management System</div>
          <div class="serviceItem"><span class="star">⭐</span> Web Development</div>
          <div class="serviceItem"> <span class="star">⭐</span> App Development</div>
          <div class="serviceItem"> <span class="star">⭐</span> Digital Marketing</div>
          <div class="serviceItem"><span class="star">⭐</span> Content Writing</div>
          <div class="serviceItem"> <span class="star">⭐</span> Search Engine Optimization </div>
          <div class="serviceItem"> <span class="star">⭐</span> App Store Optimization </div>
          <div class="serviceItem"> <span class="star">⭐</span> Hospital Management System</div>
          <div class="serviceItem "> <span class="star">⭐</span> Learning Management System</div>
          <div class="serviceItem "> <span class="star">⭐</span> Enterprise Management System</div>
          <div class="serviceItem"><span class="star">⭐</span> Web Development</div>
          <div class="serviceItem"> <span class="star">⭐</span> App Development</div>
          <div class="serviceItem"> <span class="star">⭐</span> Digital Marketing</div>
          <div class="serviceItem"><span class="star">⭐</span> Content Writing</div>
          <div class="serviceItem"> <span class="star">⭐</span> Search Engine Optimization </div>
          <div class="serviceItem"> <span class="star">⭐</span> App Store Optimization </div>
          <div class="serviceItem"> <span class="star">⭐</span> Hospital Management System</div>
          <div class="serviceItem "> <span class="star">⭐</span> Learning Management System</div>
          <div class="serviceItem "> <span class="star">⭐</span> Enterprise Management System</div>
        </div>
      </section>
    </div>
  );
};

export default Servicerow;
