import React, { useState } from "react";
import OfficialLogo from '../Assets/Images/CodesBunny/Codesbunny320.png';
import { NavLink } from "react-router-dom";
import "../Assets/Style/navbar.css";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <>
      <header className="main-header clearfix">
        <div className="main-header__top-inner clearfix">
          <div className="main-header__top-right">
            <div className="main-header__top-right-text">
              <p>
                <span>Now Hiring:</span> Are you a driven and motivated 1st Line IT Support Engineer?
              </p>
            </div>
          </div>
        </div>
        <nav className="main-menu clearfix">
          <div className="main-menu-wrapper clearfix">
            <div className="main-menu-wrapper-inner clearfix">
              <div className="main-menu-wrapper__left clearfix">
                <div className="main-menu-wrapper__logo">
                  <NavLink to="/"><img src={OfficialLogo} decoding="async"  style={{height:'60px', width:'230px'}} fetchpriority="high" alt="CodesBunny" /></NavLink>
                </div>
              </div>
              <div className="main-menu-wrapper__center">
                <div className="main-menu-wrapper__main-menu">
                  <NavLink to="#" className="mobile-nav__toggler" onClick={handleToggle}>
                    <i className="fa fa-bars"></i>
                  </NavLink>
                  <ul className="main-menu__list">
                    <li><NavLink to="/" onClick={closeMobileMenu} className={({ isActive }) => (isActive ? "active" : "")}>HOME</NavLink></li>
                    <li><NavLink to="/about" onClick={closeMobileMenu} className={({ isActive }) => (isActive ? "active" : "")}>ABOUT US</NavLink></li>
                    <li className="dropdown">
                      <NavLink to="/service" onClick={toggleDropdown}>
                        SERVICES&nbsp;&nbsp; <i className="fa fa-angle-down"></i>
                      </NavLink>
                      <ul>
                        <li><NavLink to="/service" onClick={closeMobileMenu}>Services</NavLink></li>
                        <li><NavLink to="/OURPRODUCTS" onClick={closeMobileMenu}>Our Products</NavLink></li>
                        <li><NavLink to="/web" onClick={closeMobileMenu}>Web Development</NavLink></li>
                        <li><NavLink to="/Application" onClick={closeMobileMenu}>App Development</NavLink></li>
                        <li><NavLink to="/Content" onClick={closeMobileMenu}>Digital Marketing</NavLink></li>
                        <li><NavLink to="/SEO" onClick={closeMobileMenu}>Search Engine Optimization</NavLink></li>
                        <li><NavLink to="/ASO" onClick={closeMobileMenu}>App Store Optimization</NavLink></li>
                        <li><NavLink to="/Content" onClick={closeMobileMenu}>Content Writing</NavLink></li>
                      </ul>
                    </li>
                    <li><NavLink to="/blog" onClick={closeMobileMenu} className={({ isActive }) => (isActive ? "active" : "")}>BLOG</NavLink></li>
                    <li><NavLink to="/contact" onClick={closeMobileMenu} className={({ isActive }) => (isActive ? "active" : "")}>CONTACT US</NavLink></li>
                  </ul>
                </div>
              </div>
              <div className="main-menu-wrapper__right">
                <NavLink to="/contact" className="nav__btn nav-btn">Get Free Quote Now</NavLink>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div className={`mobile-nav__wrapper ${isMobileMenuOpen ? 'expanded' : ''}`}>
        <div className="mobile-nav__overlay" onClick={handleToggle}></div>
        <div className="mobile-nav__content">
          <span className="mobile-nav__close mobile-nav__toggler" onClick={handleToggle}>
            <i className="fa fa-times"></i>
          </span>
          <div className="logo-box">
            <NavLink to="/" aria-label="logo image"><img src={OfficialLogo}  style={{height:'60px', width:'230px'}}  decoding="async" fetchpriority="high" alt="OfficialLogo" /></NavLink>
          </div>
          <ul className="main-menu__list">
            <li><NavLink to="/" onClick={closeMobileMenu} activeClassName="active">HOME</NavLink></li>
            <li><NavLink to="/about" onClick={closeMobileMenu} activeClassName="active">ABOUT US</NavLink></li>
            <li className={`dropdown ${isDropdownOpen ? 'open' : ''}`}>
              <NavLink to="/service" onClick={toggleDropdown}>
                SERVICES &nbsp;<i className="fa fa-angle-down"></i>
              </NavLink>
              <ul className={isDropdownOpen ? 'open' : ''}>
                <li><NavLink to="/service" onClick={closeMobileMenu}>Services</NavLink></li>
                <li><NavLink to="/OURPRODUCTS" onClick={closeMobileMenu}>Our Products</NavLink></li>
                <li><NavLink to="/web" onClick={closeMobileMenu}>Web Development</NavLink></li>
                <li><NavLink to="/Application" onClick={closeMobileMenu}>App Development</NavLink></li>
                <li><NavLink to="/Content" onClick={closeMobileMenu}>Digital Marketing</NavLink></li>
                <li><NavLink to="/SEO" onClick={closeMobileMenu}>Search Engine Optimization</NavLink></li>
                <li><NavLink to="/Aso" onClick={closeMobileMenu}>App Store Optimization</NavLink></li>
                <li><NavLink to="/Content" onClick={closeMobileMenu}>Content Writing</NavLink></li>
              </ul>
            </li>
            <li><NavLink to="/blog" onClick={closeMobileMenu} activeClassName="active">BLOG</NavLink></li>
            <li><NavLink to="/contact" onClick={closeMobileMenu} activeClassName="active">CONTACT US</NavLink></li>
          </ul>
          <ul className="mobile-nav__contact list-unstyled">
            <li><i className="fa fa-envelope"></i><a href="mailto:officialcodesbunny@gmail.com">info@codesbunny.com</a></li>
            <li><i className="fa fa-phone-alt"></i><a href="tel:+92 308 0044190">+92 308 0044190</a></li>
          </ul>
          <div className="mobile-nav__top"></div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
