import web from "../Assets/Images/CodesBunny/WebV.png"
import App from "../Assets/Images/CodesBunny/mobV.png"
import hmsV from "../Assets/Images/CodesBunny/hmsv.png"
import digi from "../Assets/Images/CodesBunny/Media.png"
import SEO from "../Assets/Images/CodesBunny/seoV.png"
import con from "../Assets/Images/CodesBunny/ContentV.png"
import erp from "../Assets/Images/CodesBunny/erpv.png"
import Cyber from "../Assets/Images/CodesBunny/CyberV.png"
import ASO from "../Assets/Images/CodesBunny/ASOV.png"



const Sdata = [
    {
        id: 1,
        imgsrc: web,
        title: "Web Development",
        des: "Web Development Enhance your online presence with custom solutions. We build responsive, user-friendly websites that drive engagement and conversions.",
        link: "/web",
    },
    {
        id: 2,
        imgsrc: App,
        title: "App Development",
        des: "Transform your ideas into powerful Android applications. Our expert developers create high-performance apps tailored to your business needs.",
        link: "/Application",
    },
    {
        id: 3,
        imgsrc: hmsV,
        title: "Hospital Management System",
        des: "It offers a comprehensive suite of tools that manage patient information, appointments, billing, and medical records with ease and precision. ",
        link: "/OURPRODUCTS",
    },
    {
        id: 4,
        imgsrc: digi,
        title: "Digital Marketing",
        des: "Boost your brand's visibility and reach with our digital marketing services. We offer SEO, social media marketing, and PPC strategies to grow your business.",
        link: "/Content",
    },
    {
        id: 5,
        imgsrc: con,
        title: "Content Writing",
        des: "Our content writing services focus on creating compelling, SEO-optimized content that resonates with your audience and grow you bussiness.",
        link: "/Content",
    },
    {
        id: 6,
        imgsrc: SEO,
        title: "SEO",
        des: " Our Team Provide SEO services are designed to improve your website's visibility and ranking on search engines resonates with your audience.",
        link: "/SEO",
    },
    {
        id: 7,
        imgsrc: ASO,
        title: "App Store Optimization",
        des: " Our Team Provide ASO services to improve your app's visibility and ranking in app stores that make good engagement with audience.",
        link: "/ASO",
    },
    {
        id: 8,
        imgsrc: App,
        title: "Data Science",
        des: "By harnessing advanced analytical techniques, we extract valuable insights, predict future trends, and optimize business operations.",
        link: "/Dataandcyber",
    },
    {
        id: 9,
        imgsrc: Cyber,
        title: "Cyber Security",
        des: "  Our experts provide proactive protection implement robust security that protect your sensitive information, systems, and networks from cyberattacks.",
        link: "/Dataandcyber",
    },
    {
        id: 10,
        imgsrc: erp,
        title: "Enterprises Resource Plan ",
        des: "Enterprise Resource Planning (ERP) is a system that integrates and manages a company's core business processes in real-time.",
        link: "/OURPRODUCTS"
    },
]
export default Sdata